<template>
  <div class="main">
    <v-head></v-head>
    <v-sidebar></v-sidebar>
    <div class="content-box" :class="{'content-collapse':$store.state.Iscollapse}">
      <div class="content">
        <el-collapse-transition>
          <router-view></router-view>
        </el-collapse-transition>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
  </div>

</template>

<script>
import vHead from './Header.vue';
import vSidebar from './Sidebar.vue';

export default {
  data() {
    return {}
  },
  components: {
    vHead,
    vSidebar,
  },
  created() {

  },
  beforeCreate() {

  },
  mounted() {

  },
  beforeMount() {

  },
  beforeUpdate() {

  },
  updated() {

  },
  beforeDestroy() {

  },
  destroyed() {

  }
}
</script>

<style lang="less" scoped>
.main /deep/ .el-card {
  margin-top: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15) !important;
}

.content-box {
  position: absolute;
  left: 250px;
  right: 0;
  top: 70px;
  bottom: 0;
  padding-bottom: 0px;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  background: #f0f0f0;
}

.content {
  width: auto;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 30px;
}
</style>
