<template>
  <div class="header">
    <div class="h-left">
      <div class="collapse-btn" @click="ChangeCollapse">
        <i :class="$store.state.Iscollapse? 'el-icon-s-unfold':'el-icon-s-fold' "></i>
      </div>
      <div class="login-txt">后台管理系统</div>
    </div>
    <div class="h-right">
      <div class="header-user-con">

        <!-- 全屏显示 -->
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>

        <div class="btn-bell">
          <router-link to="/">
            <i class="el-icon-bell"></i>
          </router-link>
          <span class="btn-bell-badge"></span>
        </div>


        <div class="user-avator">
          <img src="" alt="">
        </div>

        <div class="user-name el-dropdown">
          <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                    xh<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix" command="logout">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullscreen: false,
    }
  },
  methods: {
    ChangeCollapse() {
      this.$store.commit("ChangeIscollapse", this.$store.state.Iscollapse = !this.$store.state.Iscollapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == 'logout') {
        this.$cookies.remove('token')   // return this
        this.$router.push('/login');
      }
    },
  }
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
  background-color: #242f42;
  display: flex;
  justify-content: space-between;

  .h-left {
    display: flex;

    .collapse-btn {
      padding: 0 21px;
      cursor: pointer;
      line-height: 70px;
    }

    .login-txt {
      width: 250px;
      line-height: 70px;
    }
  }

  .h-right {
    padding-right: 50px;

    .header-user-con {
      display: flex;
      height: 70px;
      align-items: center;

      .btn-fullscreen, .btn-bell {
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;

        a {
          color: #fff;

        }

        .btn-bell-badge {
          position: absolute;
          right: 0;
          top: -2px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #f56c6c;
          color: #fff;
        }
      }

      .btn-fullscreen {
        transform: rotate(45deg);
        margin-right: 5px;
        font-size: 24px;
      }

      .user-avator {
        margin-left: 20px;

        img {
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      .user-name {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style>
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
</style>